<template>
  <div id="Softening">
    <div class="baner"></div>
    <div class="box">
      <div class="bix-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">公司软著</div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="box-li-text fs18 hui333 tac">
          杭州聚达物联科技有限公司，专注于智慧社区生活服务领域，秉持着“产品为核心，以技术为桥梁，以品质为保障”的理念，以“聚达之家”APP
          为基石，提供管家式贴心服务，畅享智慧化人居体验，构建便捷邻里生活圈，创新高效社区管理模式，立足科技心向未来，探索社区数字化生活新方式。
        </div>
        <div class="box-li-img"></div>
      </div>
    </div>
    <Fuwu />
  </div>
</template>

<script>
import Fuwu from "@/components/Fuwu.vue";
export default {
  name: "Softening",
    components: {
    // Tabbar,
    // Bottombox,
    Fuwu,
  },
};
</script>

<style scoped lang="less">
#Softening {
  .baner {
    width: 1920px;
    height: 576px;
    background-image: url(~@/assets/Softening1.png);
    background-size: 100% 100%;
  }
  .box {
    width: 1200px;
    margin: 0 360px;
    .bix-li {
      width: 1200px;
      .box-li-tiao {
        width: 1200px;
        height: 6px;
        margin-top: 21px;
        .box-li-tiao-L {
          width: 64px;
          height: 6px;
          background: #f8951d;
          margin-right: 8px;
        }
        .box-li-tiao-R {
          width: 12px;
          height: 6px;
          background: #f8951d;
        }
      }
      .box-li-text {
        width: 1118px;
        margin: 60px 41px 116px;
        height: 114px;
        line-height: 48px;
      }
      .box-li-img {
        width: 1200px;
        height: 559px;
        background-image: url(~@/assets/Softening2.png);
        background-size: 100% 100%;
        margin-bottom: 222px;
      }
    }
  }
}
</style>